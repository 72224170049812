/* * {
  white-space: pre-line;
}  */
/* *{
  box-sizing: border-box;
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  margin: 0px;
  /* font-family: 'Open Sans', Arial, sans-serif !important; */
  font-family: "Open Sans", sans-serif !important;
}
body::-webkit-scrollbar {
  display: none !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.appBar {
  z-index: 99999;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div[class^="MuiBackdrop-root"],
div[class*="MuiBackdrop-root"] {
  /* background: none; */
}
div[class^="MuiPaper-elevation"],
div[class*="MuiPaper-elevation"] {
  box-shadow: none;
}


div[class*="MuiButtonBase-root-MuiAccordionSummary-root"] {
 height: 60px !important;
}
.css-1qltlow-MuiTabs-indicator {
  background-color: var(--primary-color) !important;
  height: 3px !important;
}

/* Targeting MUI components specifically */
.MuiTypography-root,
.MuiTable-root,
.MuiTableCell-root, .MuiButton-root {
  font-family: "Open Sans", sans-serif !important;
}
.error-text {
  color: red;
  font-size: 12px;
}

.css-13lvt8g-MuiButtonBase-root-MuiRadio-root.Mui-checked{
  color: var(--primary-color) !important;
}
/* .css-1m9128y{
  margin-top: 25px !important;
}
 .css-hn784z{
  position: absolute;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    top: 0;
    left: 0;
    
} 
.css-1abc02a{
  position: absolute;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    top: 0;
    left: 30vw !important ;
}
.css-1g04gm2-MuiButtonBase-root-MuiIconButton-root{
  color: gray !important ;
  background-color: transparent !important;  
  box-shadow: none !important; 
} */
.MuiTypography-root {
  font-family: 'Open Sans'!important;
}

.css-myw0ww-MuiPaper-root-MuiAccordion-root{
  border: none !important;
  position: static !important;
}
.css-myw0ww-MuiPaper-root-MuiAccordion-root.Mui-disabled,.css-lo78ge-MuiPaper-root-MuiAccordion-root.Mui-disabled{
  background: none !important;
}

/* .css-p8xe3-MuiStepLabel-labelContainer{
  width: max-content !important;
} */