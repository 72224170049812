body {
  margin: 0;
  /* font-family: 'Open Sans' !important; */
  font-family: "Open Sans", sans-serif !important;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-body1 {
  font-family: "Open Sans" !important;
}
.MuiTypography-body2{
  font-family: "Open Sans" !important;
}
 
/* Targeting MUI components specifically */
.MuiTypography-root, 
.MuiTable-root,.MuiTableCell-root {
    font-family: "Open Sans", sans-serif !important;
}