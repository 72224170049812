.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(243, 244, 246, 0.9); 
  }
  
  .spinner {
    border: 8px solid #f3f3f3; 
    border-top: 8px solid #51459E;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  