.body {
  font-family: Arial, sans-serif;
  /* background-color: #ffff; */
  background-image: url("../../assets/images/bgimg.png");
  background-size: cover;
  color: #ffffff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color) !important;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
   margin-top: 16px !important; 
}
.a {
  display: block;
  text-align: center;
  margin-top: 10px;
  color: var(--primary-color) !important;
  text-decoration: none !important;
}
.feildsmargin{
  margin-top: 16px !important; 
}
.logo{
  margin-bottom: 8px;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px; 
}
